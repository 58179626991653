import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby"
import './assets/styles/_index.scss';
import { GetPropertyOffice } from '../../queries/common_use_query';
const PropertyDetailsViewing = (props) => {
    const {loading: loading,error: error,data: office_data} = GetPropertyOffice(props?.propertyData?.office_crm_id)    
    var officecontact = "020 3000 6787"
    if(office_data && office_data.offices.length > 0) {
        officecontact = office_data.offices[0].Phone;
    }
    return (
        <section className="property-viewing-wrapper">
            <Container>
                <Row>
                    <Col className="d-md-flex align-items-center justify-content-center">
                        <Link to={`/book-a-viewing?pid=${props?.propertyData?.id}`} className="btn btn-white-outline">Arrange a Viewing</Link>
                        <div className="call-us">or call us on <a href={`tel:${officecontact}`}>{officecontact}</a></div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PropertyDetailsViewing
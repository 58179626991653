/**
 * Mortgage Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types"
import { Form, Button, Container, Row, Col } from "react-bootstrap"
import { filterNumber, numberFormat } from "./util"
import {
    calculate,
    countries,
    // propertyTypes,
    // buyerTypes,
} from "@starberry/react-calculator-stampduty"
import $ from "jquery";

// we could user uk-ireland-stampduty-calculator's propertyTypes & buyerTypes
// but key val par diff we could get valus using Object.values but it is not
// supported in IE - https://caniuse.com/#feat=object-values
// so defined our our custom key pairs

const propertyTypes = [
    {
        value: "residential",
        label: "Residential",
    },
    {
        value: "commercial",
        label: "Commercial",
    },
]

const buyerTypes = [
    {
        value: "first",
        label: "I’m buying my first home or next home",
    },
    {
        value: "investor",
        label: "I’m buying an additional property or second home",
    },
]

// use project specific import here

// DO NOT DO ANY CHNAGES - START
const StampdutyCalc = (props) => {
    const currency = props.currency

    const [result, setResult] = useState(false)

    const [propertyType, setPropertyType] = useState(props.propertyType)
    const [propertyValue, setPropertyValue] = useState(
        currency + numberFormat(filterNumber(props.propertyValue))
    )
    const [buyer, setBuyer] = useState(props.buyerType)

    const formatPrice = str => {
        return currency + str.toLocaleString("en-US")
    }

    const doCalculate = (purchase_price_price) => {
        const results = calculate(
            filterNumber(purchase_price_price ? purchase_price_price : propertyValue),
            propertyType,
            countries.ENGLAND,
            buyer
        )
        // effective stamp duty rates
        // Formula => ((tax / price) * 100).
        // Ex: (213750/2500000) * 100 = 8.55%.
        let effectiveRate = (results.tax / filterNumber(propertyValue)) * 100
        effectiveRate = new Intl.NumberFormat("en-US", {}).format(effectiveRate)
        let summaryBands = []
        results.summaryBands.map(result => {
            summaryBands.push(
                result.adjustedRate +
                "% between " +
                formatPrice(result.start) +
                " and " +
                formatPrice(result.end)
            )
        })
        const result = {
            effectiveRate: effectiveRate + "%",
            summaryBands: summaryBands,
            tax: formatPrice(results.tax),
        }
        setResult(result)
    }

    const handleSubmit = event => {
        const shareurl = typeof window !== 'undefined' ? window.location.href : ''
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Stampduty calculator',
            'formType': shareurl,
            'formId': 'Stampduty calculator',
            'formName': 'Stampduty calculator',
            'formLabel': 'Stampduty calculator'
        }); 
        event.preventDefault()
        event.stopPropagation()
        doCalculate()
    }

    const handleDeposit = event => {
        var purchase_price_price = currency + numberFormat(filterNumber(event.target.value));
        doCalculate(purchase_price_price);
        setPropertyValue(purchase_price_price)
    }

    const handlePropertyType = event => {
        setPropertyType(event.target.value)
    }

    const handleBuyer = event => {
        setBuyer(event.target.value)
        setTimeout(function () {
            $('.btn-stamp-calculate').click();
        }, 300);
    }

    // DO NOT DO ANY CHNAGES - END
    //console.log('propertyValue',propertyValue)
    useEffect(() => {
        doCalculate()
        $('.btn-stamp-calculate').click();
    }, []);
    return (
        <div className="form mortgate-form">
            <div className='calc-st-wrap'>
                <p>Find out how much you will have to pay by using our Stamp Duty Calculator.</p>
                <div className="form-property-calculator">
                    <Form
                        name="MortgageCalc"
                        noValidate
                        onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <div className="form-stamp form-field">
                            <input type="hidden" name="bot-field" />
                            <Form.Group controlId="deposit" className="form-group">
                                <Form.Label>Purchase Price</Form.Label>
                                <div className="form-addon-left form-border-input">
                                    <Form.Control
                                        className="form-control"
                                        required
                                        type="text"
                                        name="deposit"
                                        value={propertyValue}
                                        placeholder="Purchase Price"
                                        onChange={handleDeposit}
                                        maxLength={13}
                                    />
                                    <span></span>
                                </div>
                            </Form.Group>

                            <Form.Group controlId="property_type" className="d-none">
                                {propertyTypes.map((item, i) => {
                                    return (
                                        <Form.Check
                                            key={i}
                                            type="radio"
                                            name="property_type"
                                            id={`default-radio-${i}`}
                                            value={item.value}
                                            label={item.label}
                                            checked={propertyType === item.value}
                                            onChange={handlePropertyType}
                                        />
                                    )
                                })}
                            </Form.Group>
                            <div className="stamp-group">
                                <div className="stamp-check-section">
                                    {buyerTypes.map((item, i) => {
                                        return (
                                            <Form.Group controlId="buyer">
                                                <Form.Check
                                                    key={i}
                                                    type="radio"
                                                    name="buyer"
                                                    id={`default-buyer-${i}`}
                                                    value={item.value}
                                                    label={item.label}
                                                    checked={buyer === item.value}
                                                    onChange={handleBuyer}
                                                />
                                            </Form.Group>
                                        )
                                    })}
                                </div>
                            </div>
                            <Button type="submit" className="d-none btn btn-pink btn-stamp-calculate">
                                Calculate
                            </Button>
                        </div>
                        <div className="total-calculate">
                                <div className="calculate-text">Stamp Duty:</div>
                                <div class="calculate-result">{result.tax}</div>
                        </div>
                        <div className="form-col d-none">
                            <Form.Group className="stamp-duty-field">
                                <Form.Label>Stamp Duty:</Form.Label>
                                <span className="mrp-amount">{result.tax}</span>
                                {result && (
                                    <p className="stampdutytext">
                                        <span>You have to pay stamp duty:</span>
                                        {result.summaryBands.map((sm, i) => {
                                            return <span key={i}>{sm}</span>
                                        })}
                                        <span>
                                            . Your effective stamp duty rate is {result.effectiveRate}
                                        </span>
                                        .
                                    </p>
                                )}
                            </Form.Group>
                        </div>
                    </Form>
                </div>
            </div>
            {result && (
                <div className="alert-success d-none">
                    <div>
                        <h3>
                            Stamp Duty: <strong>{result.tax}</strong>
                        </h3>
                        <p>
                            <span>You have to pay stamp duty:</span>
                            {result.summaryBands.map((sm, i) => {
                                return <span key={i}>{sm}</span>
                            })}
                            <span>
                                Your effective stamp duty rate is {result.effectiveRate}
                            </span>
                            .
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

StampdutyCalc.defaultProps = {
    propertyType: `residential`,
    propertyValue: `0`,
    buyerType: `first`,
    currency: process.env.CURRENCY ? process.env.CURRENCY : "£",
}

StampdutyCalc.propTypes = {
    propertyType: PropTypes.string,
    propertyValue: PropTypes.any,
    buyerType: PropTypes.string,
    currency: PropTypes.string,
}

export default StampdutyCalc


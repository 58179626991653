import React, { useState } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import GetGGFXImage from "../common/site/get-ggfx-image";
import NoImage from "../../images/no-image.png";
import './assets/styles/_index.scss';

const PropertyDetailsBanner = (props) => {

    let processedImages = JSON.stringify({});
    if (props?.propertyData?.imagetransforms?.images_Transforms) {
      processedImages = props.propertyData.imagetransforms.images_Transforms;
    }

    // Property images lightbox
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const openPropertyImage = (e, ind) => {
        e.preventDefault()
        setPhotoIndex(ind)
        setIsOpen(true)
    }
    // Property images lightbox

    // Property details images lightbox
    const propertyImages = props?.propertyData?.images
    var propertyLightImages =
        propertyImages && propertyImages.map(img => img.url)
    // Property details images lightbox

    // Floorplan images lightbox
    const [floorIndex, setFloorIndex] = useState(0)
    const [isOpenFloor, setIsOpenFloor] = useState(false)

    const openFloorplanImage = (e, ind) => {
        e.preventDefault()
        setFloorIndex(ind)
        setIsOpenFloor(true)
    }

    const floorplanImages = props?.propertyData?.floorplan
    var floorplanLightImages =
        floorplanImages && floorplanImages.map(floorImg => floorImg.url)
    // Floorplan images lightbox


    // Epc images lightbox
    const [epcIndex, setEpcIndex] = useState(0)
    const [isOpenEpc, setIsOpenEpc] = useState(false)

    const openEpcImage = (e, ind) => {
        e.preventDefault()
        setEpcIndex(ind)
        setIsOpenEpc(true)
    }

    const epcImages = props?.propertyData?.epc
    var epcLightImages =
        epcImages && epcImages.map(epcImg => epcImg.url)
    // Epc images lightbox



    var propertystatus= ""
    if(props?.propertyData?.status == "Sold" || props?.propertyData?.status == "Sold STC" || props?.propertyData?.status == "Let Agreed") {
      propertystatus = "true";
    }
    return (
        <section className="property-details-banner">
            <Container>
                <Row>
                    <Col xl={8} lg={12}>
                        <picture className="property-details-first-banner">
                            {propertystatus == "true" ?
                            <div className='pstatus'>
                                {props?.propertyData?.status}
                            </div>
                            :""}
                            {
                                props?.propertyData?.images?.length > 0 ? (
                                    <div  onClick={e => openPropertyImage(e, 0)}>
                                    <GetGGFXImage 
                                        imagename={"property.images.detail"}
                                        imagesource={props?.propertyData?.images[0]}
                                        fallbackalt={props?.propertyData?.display_address}
                                        imagetransformresult={processedImages}
                                        id={props?.propertyData?.id}
                                    />
                                    </div>
                                )
                                :
                                <img src={NoImage} alt="Banner Img" />
                            }
                            <ul className="list-inline property-details-options d-md-block d-none">
                                {
                                    props?.propertyData?.images?.length > 0 &&
                                    <li className="list-inline-item">
                                        <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)} className="d-flex align-items-center justify-content-center">Gallery <i className="icon icon-gallery"></i></a>
                                    </li>
                                }
                                {
                                    props?.propertyData?.virtual_tour?.length > 0 &&
                                    <li className="list-inline-item">
                                        <a href="javascript:void(0)" className="d-flex align-items-center justify-content-center">Video <i className="icon icon-video"></i></a>
                                    </li>
                                }
                                {
                                    props?.propertyData?.floorplan?.length > 0 &&
                                    <li className="list-inline-item">
                                        <a href="javascript:void(0)" onClick={e => openFloorplanImage(e, 0)} className="d-flex align-items-center justify-content-center">Floorplan <i className="icon icon-floorplan"></i></a>
                                    </li>
                                }

                                {
                                    props?.propertyData?.epc?.length > 0 &&
                                    <li className="list-inline-item">
                                        <a href="javascript:void(0)" onClick={e => openEpcImage(e, 0)} className="d-flex align-items-center justify-content-center">Epc <i className="icon icon-epc"></i></a>
                                    </li>
                                }
                                {
                                    props?.propertyData?.brochure?.length > 0 &&
                                    <li className="list-inline-item">
                                        <a target={`_blank`} href={props?.propertyData?.brochure[0].srcUrl} className="d-flex align-items-center justify-content-center">Brochure <i className="icon icon-brochure"></i></a>
                                    </li>
                                }
                            </ul>
                        </picture>
                        <ul className="list-inline property-details-options-mobile d-md-none d-block">
                            {
                                props?.propertyData?.images?.length > 0 &&
                                <li className="list-inline-item">
                                    <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)} className="d-flex align-items-center justify-content-center"><span>Gallery </span><i className="icon icon-gallery"></i></a>
                                </li>
                            }
                            {
                                props?.propertyData?.virtual_tour?.length > 0 &&
                                <li className="list-inline-item">
                                    <a href="javascript:void(0)" className="d-flex align-items-center justify-content-center">Video <i className="icon icon-video"></i></a>
                                </li>
                            }
                            {
                                props?.propertyData?.floorplan?.length > 0 &&
                                <li className="list-inline-item">
                                    <a href="javascript:void(0)" onClick={e => openFloorplanImage(e, 0)} className="d-flex align-items-center justify-content-center"><span>Floorplan </span><i className="icon icon-floorplan"></i></a>
                                </li>
                            }
                            {
                                props?.propertyData?.epc?.length > 0 &&
                                <li className="list-inline-item">
                                    <a href="javascript:void(0)" onClick={e => openEpcImage(e, 0)} className="d-flex align-items-center justify-content-center"><span>Epc </span><i className="icon icon-epc"></i></a>
                                </li>
                            } 
                            {
                                props?.propertyData?.brochure?.length > 0 &&
                                <li className="list-inline-item">
                                    <a target={`_blank`} href={props?.propertyData?.brochure[0].srcUrl} className="d-flex align-items-center justify-content-center"><span>Brochure </span><i className="icon icon-brochure"></i></a>
                                </li>
                            }                            
                        </ul>
                    </Col>
                    <Col lg={4} className="d-xl-block d-none banner-secondary">
                        <div className="property-details-second-banner d-flex align-content-between flex-wrap">
                            <picture className="img-banner-zoom" onClick={e => openPropertyImage(e, 1)}>
                                {
                                    props?.propertyData?.images?.length > 0 ? (
                                        <GetGGFXImage 
                                            imagename={"property.images.detail2"}
                                            imagesource={props?.propertyData?.images[1]}
                                            fallbackalt={props?.propertyData?.display_address}
                                            imagetransformresult={processedImages}
                                            id={props?.propertyData?.id}
                                        />
                                    )
                                    :
                                    <img src={NoImage} alt="Banner Img" />
                                }
                            </picture>
                            <picture className="img-banner-zoom" onClick={e => openPropertyImage(e, 2)}>
                                {
                                    props?.propertyData?.images?.length > 0 ? (
                                        <GetGGFXImage 
                                            imagename={"property.images.detail2"}
                                            imagesource={props?.propertyData?.images[2]}
                                            fallbackalt={props?.propertyData?.display_address}
                                            imagetransformresult={processedImages}
                                            id={props?.propertyData?.id}
                                        />
                                    )
                                    :
                                    <img src={NoImage} alt="Banner Img" />
                                }
                            </picture>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Banner image popup */}
            {isOpen && (
                <Lightbox
                    mainSrc={propertyLightImages[photoIndex]}
                    nextSrc={
                        propertyLightImages[
                        (photoIndex + 1) % propertyLightImages.length
                        ]
                    }
                    prevSrc={
                        propertyLightImages[
                        (photoIndex + propertyLightImages.length - 1) %
                            propertyLightImages.length
                        ]
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                        (photoIndex + propertyLightImages.length - 1) %
                            propertyLightImages.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
                    }
                />
            )}
            {/* Banner image popup */}

            {/* Floorplan image popup */}
            {isOpenFloor && (
                <Lightbox
                mainSrc={floorplanLightImages[floorIndex]}
                nextSrc={
                    floorplanLightImages[
                    (floorIndex + 1) % floorplanLightImages.length
                    ]
                }
                prevSrc={
                    floorplanLightImages[
                    (floorIndex + floorplanLightImages.length - 1) %
                        floorplanLightImages.length
                    ]
                }
                onCloseRequest={() => setIsOpenFloor(false)}
                onMovePrevRequest={() =>
                    setFloorIndex(
                    (floorIndex + floorplanLightImages.length - 1) %
                        floorplanLightImages.length
                    )
                }
                onMoveNextRequest={() =>
                    setFloorIndex((floorIndex + 1) % floorplanLightImages.length)
                }
                />
            )}
            {/* Floorplan image popup */}

            {/* Epc image popup */}
            {isOpenEpc && (
                <Lightbox
                mainSrc={epcLightImages[floorIndex]}
                nextSrc={
                    epcLightImages[
                    (floorIndex + 1) % epcLightImages.length
                    ]
                }
                prevSrc={
                    epcLightImages[
                    (floorIndex + epcLightImages.length - 1) %
                        epcLightImages.length
                    ]
                }
                onCloseRequest={() => setIsOpenEpc(false)}
                onMovePrevRequest={() =>
                    setEpcIndex(
                    (floorIndex + epcLightImages.length - 1) %
                        epcLightImages.length
                    )
                }
                onMoveNextRequest={() =>
                    setEpcIndex((floorIndex + 1) % epcLightImages.length)
                }
                />
            )}
            {/* Epc image popup */}

        </section>
    )
}

export default PropertyDetailsBanner
import React, { useState, useEffect } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GetGGFXImage from '../common/site/get-ggfx-image';
import './assets/styles/_index.scss';

const SimilarProperties = (props) => {

    const [propItems, setPropItems] = useState(false)
    
    const getitems = async url => {
        try {
          const { data } = await axios.get(url, {})
          setPropItems(data)
        } catch (error) {
          console.error(error)
        }
    }

    useEffect(() => {
        let url = process.env.GATSBY_STRAPI_SRC + `/stb-lists/item/Property-details?pid=` +props?.propertyData?.id;
        getitems(url)
    },[])

    // Slider settings
    let settings = {
        dots: false,
        arrows: true,
        infinite: propItems?.length > 3,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
        {
            breakpoint: 1200,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: propItems?.length > 3,
            arrows: true,
            dots: false,
            },
        },
        {
            breakpoint: 992,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: propItems?.length > 2,
            arrows: false,
            dots: true,
            },
        },
        {
            breakpoint: 767,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: propItems?.length > 1,
            arrows: false,
            dots: true,
            },
        },
        ],
    }
    // Slider settings

    return (
        <>
            {
                propItems && propItems.length > 0 &&
                <section className="featured-properties-wrapper similar-properties section-p120">
                    <Container>
                        <Row className="justify-content-center text-center featured-properties-header">
                            <Col lg={8}>
                                <h2>Similar properties that may interest you</h2>
                                <p>Explore our collection of <Link to="/property/for-sale/in-london/">property for sale</Link> or <Link to="/property/to-rent/in-london/">property to rent.</Link></p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Slider className="featured-properties-slider" {...settings}>
                                    {
                                        propItems && propItems.map((item, key) => {

                                            let processedImages = JSON.stringify({});
                                            if (item?.imagetransforms?.images_Transforms) {
                                                processedImages = item.imagetransforms.images_Transforms;
                                            }

                                            let propertyUrl = (item.search_type === "sales" ? "/property-for-sale/" : "/property-to-rent/")+item.slug+"-"+item.id+"/";
                                                
                                            return (
                                                <div className="featured-properties-slide" key={key}>
                                                    <div className="featured-properties-img-zoom">
                                                        <Link to={propertyUrl}>
                                                            <GetGGFXImage 
                                                                imagename={"property.images.featuredProperties"} 
                                                                imagesource={item?.images[0]}
                                                                fallbackalt={item?.display_address} 
                                                                imagetransformresult={processedImages} 
                                                                id={item?.id}
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div className="featured-properties-details">
                                                        {
                                                            item?.display_address &&
                                                            <div className="featured-properties-title"><Link to={propertyUrl}>{item?.display_address}</Link></div>
                                                        }
                                                        {
                                                            item?.price &&
                                                            <div className="featured-properties-price">£{item?.price.toLocaleString()} {item?.price_qualifier}</div>
                                                        }                                                        
                                                        <ul className="list-inline mb-0 featured-properties-room-details">
                                                            {
                                                                item?.bedroom!=0 && item?.bedroom &&
                                                                <li className="list-inline-item mb-0"><i className="icon icon-bedroom-smilar"></i> {item.bedroom}</li>
                                                            }
                                                            {
                                                                item?.bathroom!=0 && item?.bathroom &&
                                                                <li className="list-inline-item mb-0"><i className="icon icon-bathroom-smilar"></i> {item?.bathroom}</li>
                                                            }
                                                            {
                                                                item?.reception!=0 && item?.reception &&
                                                                <li className="list-inline-item mb-0"><i className="icon icon-reception-smilar"></i> {item?.reception}</li>
                                                            }
                                                            {
                                                                item?.floor_area!=0 && item?.floor_area &&
                                                                <li className="list-inline-item mb-0"><i className="icon icon-floor-smilar"></i> {item?.floor_area.toLocaleString()} sq ft</li>
                                                            }                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </section>
            }
        </>
    )
}

export default SimilarProperties
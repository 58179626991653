import { Link } from "@reach/router";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import { GetPropertyOffice } from "../../queries/common_use_query";
import "./PropertyDetailsFloatingButton.scss";

const PropertyDetailsFloatingButton = (props) => {
  const {
    loading: loading,
    error: error,
    data: office_data,
  } = GetPropertyOffice(props?.propertyData?.office_crm_id);

  var officecontact = "020 3000 6787";
  if (office_data && office_data.offices.length > 0) {
    officecontact = office_data.offices[0].Phone;
  }
  return (
    <section className="floating-wrapper d-lg-none d-flex align-items-center">
      <Link to={`/book-a-viewing?pid=${props?.propertyData?.id}`} className="btn btn-fill-primary">
        {isMobileOnly ? <span>Enquire <i className="icon icon-envelope"></i></span> : "Arrange a Viewing"}
      </Link>
      <a href={`tel:${officecontact}`} className="btn btn-fill-primary">
      {isMobileOnly ? <span>Call <i className="icon icon-phone"></i></span> : <span>Call us on {officecontact}</span>}
      </a>
    </section>
  );
};

export default PropertyDetailsFloatingButton;

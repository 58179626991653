import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import parse from "html-react-parser";
import { Link } from "gatsby"
import './assets/styles/_index.scss';
import NegoImg from "../../images/nego_img.png";
import { GetPropertyOffice } from '../../queries/common_use_query';
import $ from 'jquery';
import { isMobile } from 'react-device-detect';
import { OfficeBaseURL } from '../common/site/utils';
const PropertyDetailsDescription = (props) => {
    
    const [scroll, setScroll] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 500)
        })
    },[])
    function gotohash(event) {
        var thishash = event;
        $('html, body').animate({
          scrollTop: $(thishash).offset().top - 70
        }, 1000);
    }
    const {loading: loading,error: error,data: office_data} = GetPropertyOffice(props?.propertyData?.office_crm_id)    
    var officeimg = ""
    var officename = ""
    var officecontact = ""
    var officeurl = ""
    if(office_data && office_data.offices.length > 0) {
        officeimg = office_data.offices[0].Tile_Image.url;
        officename = office_data.offices[0].Name;
        officecontact = office_data.offices[0].Phone;
        officeurl = office_data.offices[0].URL
    }
    return (
        <section className="property-details-description-wrapper">
            <Container>
                <Row>
                    <Col lg={8}>
                        {
                            props?.propertyData?.display_address &&
                            <h2>{props?.propertyData?.display_address}</h2>
                        }

                        {
                            props?.propertyData?.price &&
                            <div className="property-details-price-wrapper">
                                <div className="property-details-price">£{props?.propertyData?.price.toLocaleString()} {props?.propertyData?.price_qualifier}</div>
                                {props?.propertyData?.search_type === "sales" && <a href="javascript:;" onClick={() => gotohash("#section-calculators")}>Calculate the mortgage and stamp duty</a>}
                            </div>                            
                        }
                        <ul className="list-inlnie mb-0 property-details-room-details">
                            {
                                props?.propertyData?.bedroom !=0 && props?.propertyData?.bedroom &&
                                <li className="list-inline-item mb-0">
                                    <i className="icon icon-bedroom"></i> {props?.propertyData?.bedroom} {isMobile ? '' : 'Bedrooms'}
                                </li>
                            }
                            {
                                props?.propertyData?.bathroom !=0  && props?.propertyData?.bathroom &&
                                <li className="list-inline-item mb-0">
                                    <i className="icon icon-bathroom"></i> {props?.propertyData?.bathroom} {isMobile ? '' : 'Bathrooms'}
                                </li>
                            }
                            {
                                props?.propertyData?.reception !=0 && props?.propertyData?.reception &&
                                <li className="list-inline-item mb-0">
                                    <i className="icon icon-reception"></i> {props?.propertyData?.reception} {isMobile ? '' : 'Reception'}
                                </li>
                            }
                            {
                                props?.propertyData?.floor_area !=0 && props?.propertyData?.floor_area &&
                                <li className="list-inline-item mb-0">
                                    <i className="icon icon-floor"></i> {props?.propertyData?.floor_area.toLocaleString()} {props?.propertyData?.floorarea_type}
                                </li>
                            }
                        </ul>
                        {
                            props?.propertyData?.accomadation_summary.length > 0 &&
                            <div className="property-details-features">
                                <h3>Standout features</h3>
                                <ul className="list-unstyled">
                                    {
                                        props?.propertyData?.accomadation_summary.map((item, i)=> {
                                            return (
                                                <li key={i} className="d-flex align-items-center"><i className="icon icon-tick"></i> {item}</li>
                                            )
                                        })
                                    }                                    
                                </ul>
                            </div>
                        }
                        {
                            props?.propertyData?.long_description &&
                            <div className="property-description">
                                <h3>Everything you need to know</h3>
                                <p>{parse(props?.propertyData?.long_description)}</p>
                            </div>
                        }
                        <div className="property-other">
                            <h3>Other details</h3>
                            <Row>
                                {
                                    props?.propertyData?.extra?.propertyTenure &&
                                    <Col lg={4} md={6} xs={6}>
                                        <div className="other-details border-btm">
                                            <div className="other-details-heading">Tenure</div>
                                            <div className="other-details-text">{props?.propertyData?.extra?.propertyTenure}</div>
                                        </div>
                                    </Col>
                                }
                                {/* <Col lg={4} md={6} xs={6}>
                                    <div className="other-details border-btm">
                                        <div className="other-details-heading">Council Tax Band</div>
                                        <div className="other-details-text">G</div>
                                    </div>
                                </Col>
                                <Col lg={4} md={6} xs={6}>
                                    <div className="other-details border-btm">
                                        <div className="other-details-heading">EPC</div>
                                        <div className="other-details-text">D</div>
                                    </div>
                                </Col> */}
                                {
                                    props?.propertyData?.extra?.buildingType &&
                                    <Col lg={4} md={6} xs={6}>
                                        <div className="other-details">
                                            <div className="other-details-heading">Type</div>
                                            <div className="other-details-text">{props?.propertyData?.extra?.buildingType}</div>
                                        </div>
                                    </Col>
                                }
                                {
                                    props?.propertyData?.extra?.referenceNumber &&
                                    <Col lg={4} md={6} xs={6}>
                                        <div className="other-details">
                                            <div className="other-details-heading">Reference</div>
                                            <div className="other-details-text">{props?.propertyData?.extra?.referenceNumber}</div>
                                        </div>
                                    </Col>
                                }
                            </Row>
                        </div>
                        <div className="property-nego-details d-xl-none d-lg-none d-block">
                            <div className="d-flex justify-content-start align-items-md-center">
                                <div className="nego-img-wrapper">
                                    <img src={officeimg} />
                                </div>
                                <div className="nego-details">
                                    <p>Contact <Link to={OfficeBaseURL+officeurl}>{officename},</Link> on</p>
                                    <p><span><a href={`tel:${officecontact}`}>{officecontact}</a></span> for any queries.</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} className="d-lg-block d-none">
                        <div className={`property-right-side-details sticky-top ${scroll ? "scrolled" : ""}`}>
                            <h3>Our team of local experts are here to help</h3>
                            {/* <p>Our team of local experts are here to help</p> */}
                            <Link to={`/book-a-viewing?pid=${props?.propertyData?.id}`} className="btn btn-fill-white">Arrange a Viewing</Link>
                            <Link to="/property-valuation/" className="btn btn-white-outline valb2">Book a Valuation</Link>
                            {props?.propertyData?.search_type === "sales" ? 
                                <Link to="/get-mortgage-advice/" className="btn btn-white-outline">Speak to our mortgage broker</Link>
                            :
                                <Link to="/tenants/rent-affordability-calculator/" className="btn btn-white-outline">Rent Affordability Calculator</Link>
                            }
                            <ul className="list-inline mb-0 nego-details-rd d-flex">
                                {/* <li className="list-inline-item m-0">
                                    <div className="nego-img-rd">
                                        <img src={officeimg} />
                                    </div>
                                </li> */}
                                <li className="list-inline-item m-0">
                                    <div className="nego-info-rd">
                                        <p>Contact <Link to={OfficeBaseURL+officeurl}>{officename}, </Link>on <span><a href={`tel:${officecontact}`}>{officecontact}</a></span> for any queries.</p>
                                        <p></p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PropertyDetailsDescription
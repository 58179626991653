import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from 'gatsby';
import parse from "html-react-parser"
import { AreaGuideDetail } from '../../queries/common_use_query'
import GetGGFXImage from "../common/site/get-ggfx-image"
import './assets/styles/_index.scss';
import NegoImg from "../../images/tile-img.png";

const PropertyAreaGuide = (props) => {

    const {loading:loading, error:error, data:data} = AreaGuideDetail(props.postcode);
    // console.log("Aaaaaaaaaaaaaa", props.postcode, data && data)
    return (
        <>
            {
                data && data?.areaguides.map((item ,i) => {
                    // let processedImages = JSON.stringify({});
                    // if (item?.imagetransforms?.Tile_Image_Transforms) {
                    //     processedImages = item.imagetransforms.Tile_Image_Transforms;
                    // }

                    var str = item.Postcode_Mapping;
                    var search = props.postcode;
                    var arr = str.split(", ") || str;

                    const tileBlockContent = item.Modules?.find((module) => module.__typename === 'ComponentComponentsAreaguideTileBlock')?.Content;
                    const regex = /(<([^>]+)>)/gi;
                    const tileBlockContentText = tileBlockContent?.replace(regex, '');

                    if (arr.indexOf(search) !== -1) {
                        let processedImages = JSON.stringify({});
                        if (item?.imagetransforms?.Tile_Image_Transforms) {
                            processedImages = item.imagetransforms.Tile_Image_Transforms;
                        }

                        return (
                            <section className="property-area-guide-wrapper tile-block-wrapper section-m120">
                                <Container>
                                    <Row className="tile-block-container">
                                        <Col lg={5} className="order-lg-1 order-2">
                                            <div className="tile-block-details-wrapper text-center">
                                                <h4>{item.Name} area guide</h4>
                                                {item.Description && <h2>{parse(item.Description)}</h2>}
                                                {tileBlockContentText && <div className="tile-block-text">{tileBlockContentText}</div>}
                                                <div className="d-flex align-items-center justify-content-center tile-block-link-wrapper">
                                                    <Link to={`/our-culture/areas-we-cover/${item.URL}/`} className="tile-block-link">Continue Reading</Link>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={1} className="order-lg-2 d-lg-block d-none"></Col>
                                        <Col lg={6} className="order-lg-3 order-1">
                                            <Link to={`/our-culture/areas-we-cover/${item.URL}/`} className="tile-img-wrapper img-zoom">
                                                <img src={item.Tile_Image?.url} />
                                            </Link>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        )
                    }

                })
            }
        </>
    )
}

export default PropertyAreaGuide
import React from 'react'
import { Container, Col, Row, Tabs, Tab } from "react-bootstrap";
import MortgageCalc from "../../components/Calculators/mortgagecalc/property-mortgage";
import StampDutyCalc from "../../components/Calculators/stampdutycalc/property-stampduty";
import { Link } from 'gatsby';
import './PropertyDetailCalculator.scss'

const Tab_Keys = {
    mortgage: 'mortgage-calculator',
    stamp: 'stamp-calculator'
}

const CONTENT = {
    mortgage: {
        title: 'Finance on this property',
        heading: 'Want to know how we can help you Finance your Dream Home…',
        content: <>
            <p>At David Astburys we pride ourselves on going above and beyond, and there is no better joy we take from helping our trusted client base move into their dream home.
            <br />
            Often, many buyers only speak to a lender who they bank with, which results in them not having access to multiple lenders.
            <br />
            Why don't you speak to our trusted panel of Advisors, to see where they can save you, like many others, thousands of pounds.
            <br />
            We are here to ger you there.
            <br />
            David Astburys are forever creating happy homes.
            </p>
        </>
    },
    stamp: {
        title: 'Finance on this property',
        heading: 'Want to know what the Stamp Duty would be for your Dream Home…',
        content: <>
            <p>Feel free to use our Stamp Duty Calculator, so you can know what you move in costs will be.</p>
        </>    
    }  
}


const PropertyDetailCalculator = (props) => {
    const [activeTab, setActiveTab] = React.useState(Tab_Keys.mortgage)

    const [content, setContent] = React.useState(CONTENT.mortgage)

    return (
        <section className='section-p120 detail-calculator-section grey-bg' id="section-calculators">
            <Container>
                <Row className="calc-block-container align-items-center">
                    <Col lg={6} className="calc-wrapper">

                        <Tabs activeKey={activeTab} onSelect={(key)=> {
                            if(key === Tab_Keys.mortgage) {
                                setContent(CONTENT.mortgage)
                            } else if(key === Tab_Keys.stamp) {
                                setContent(CONTENT.stamp)
                            }
                            setActiveTab(key)
                        }}>
                            <Tab eventKey={Tab_Keys.mortgage} title="Mortgage Calculator">
                                <div className="tab-content-block">
                                    <MortgageCalc propertyPrice={props.propertyValue} />
                                </div>
                            </Tab>
                            <Tab eventKey={Tab_Keys.stamp} title="Stamp Duty Calculator">
                                <div className="tab-content-block">
                                    <StampDutyCalc propertyValue={props.propertyValue} />
                                </div>
                            </Tab>
                        </Tabs>
                    </Col>
                    <Col lg={1} className="d-lg-block d-none"></Col>
                    <Col lg={5} className="calc-content">
                        <h3>{content.title}</h3>
                        <h2>{content.heading}</h2>
                        <div>
                            {content.content}
                        </div>
                        <Link className='btn btn-fill-primary' to="/get-mortgage-advice/">Get mortgage advice</Link>
                        <div className='mt-3 calc-disclaimer'>
                            *The following calculators are for guidance and should not be relied on. You should always seek professional independent advice from Lawyers, Accountants or Financial Advisors in relation to a particular purchase.
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PropertyDetailCalculator